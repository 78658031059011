/**
 * Search Component
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.search-results {
  /**
 * Grid Variables - CH
 */
  /**
 * Custom SASS Mixins - CH
 */
  /**
 * Grid Variables - CH
 */
}

.search-results label {
  background: transparent;
}

.search-results input, .search-results button {
  display: inline-block;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  color: #707070;
  font-weight: normal;
  padding: 8px 2%;
  clear: both;
}

.search-results input[type="submit"], .search-results input[type="reset"], .search-results input[type="button"], .search-results button {
  border: 1px solid #89C6CC;
  padding: 5px 20px;
}

.search-results input[type="submit"]:hover, .search-results input[type="reset"]:hover, .search-results input[type="button"]:hover, .search-results button:hover {
  background: #f7f7f7;
}

.search-results input::-moz-focus-inner, .search-results button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.search-results input[type="text"] {
  font-size: 13px;
}

.search-results input[type="text"], .search-results input[type="email"], .search-results input[type="password"], .search-results textarea, .search-results select {
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
  color: #707070;
  width: 100%;
}

.search-results select {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

.search-results-page .boxed > div {
  max-width: 78.7rem;
}

.search-results-page .search-results-count {
  margin-bottom: 4.5em;
  text-align: center;
  font-size: 2em;
}

.search-results.search-results--main .search-result-list > li {
  font-size: 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.search-results.search-results--main .search-result-list > li + li {
  border-top: 0;
}

.search-results.search-results--main .search-result-list > li .field-image {
  flex-basis: 30.625%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 30.625%;
  margin-right: 3rem;
}

.search-results.search-results--main .search-result-list > li .description-wrapper {
  display: block;
  width: 100%;
}

.search-results.search-results--main .search-result-list > li .field-title {
  color: #000000;
  font-family: 'Frank Ruhl Libre', serif;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1em;
  text-transform: none;
}

.search-results.search-results--main .search-result-list > li .field-title a {
  color: inherit;
  font-size: inherit;
}

.search-results.search-results--main .search-result-list > li .field-title a:hover, .search-results.search-results--main .search-result-list > li .field-title a:focus {
  color: inherit;
  text-decoration: underline;
}

.search-results.search-results--main .search-result-list > li .field-page-type {
  font-size: 0.875em;
  margin-top: 0.875em;
}

.search-results.search-results--main .search-result-list > li .field-search-results-intro {
  margin-top: 0.5em;
}

@media (max-width: 991px) {
  .search-results.search-results--main .search-result-list > li .field-image,
  .search-results.search-results--main .search-result-list > li .description-wrapper {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 100%;
  }
  .search-results.search-results--main .search-result-list > li .description-wrapper:nth-child(2) .field-title {
    margin-top: 0.66667em;
  }
}

.search-results .search-results-overlay {
  display: none;
}

.search-results.loading-in-progress {
  min-height: 200px;
}

.search-results.loading-in-progress .search-result-overlay {
  display: none;
}

.search-results.loading-more-in-progress:after {
  display: block;
  height: 100px;
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+DQogIDxwYXRoICBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZmlsbD0nIzFhODBiNicgZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4NCg==);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  content: '';
}

.search-results .no-results {
  padding: 5px;
}

.load-more input[type="submit"],
.load-more input[type="reset"],
.load-more input[type="button"],
.load-more button {
  font-size: 16px;
}
